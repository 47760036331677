<!--
 * @Author       : Hugo
 * @Date         : 2021-04-25 13:44:38
 * @LastEditTime : 2021-10-25 14:48:17
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/FunTemplate/Custom/View/File.vue
 * @Description  : [视图]文件
 * @^_^          : 
-->
<template>
  <div>
    <task
      :data="data"
      type="custom"
      :open_selected="switch_custom_multiple_file"
      :selected="data.multiple_file"
      :hide_menu="switch_custom_multiple_folder"
      @handleClick="callCustomTask"
      :call_custom="call_custom"
    ></task>
  </div>
</template>
<script>
import Task from '@/components/Common/Task.vue';
export default {
  props: {
    data:{
      type: Object,
      default : function(){
        return {
          name: 'VIVO的KV延展设计的详细要求描述。VIVO的KV延展设计的详细要求描述。VIVO的KV延展设计的详细要求描述。',
          time: new Date().getTime(),
        }
      }
    },
    call_custom: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Task
  },
  computed: {
    switch_custom_multiple_file(){
      return this.$store.state.switch_custom_multiple_file;
    },
    switch_custom_multiple_folder(){
      return this.$store.state.switch_custom_multiple_folder;
    },
  },
  watch: {
    switch_custom_multiple_file: function(val){
      if(val){
        this.$set(this.data, 'multiple_file', false);
      }
    },
    
  },
  methods: {
    doCloseMenu(){
      this.$children[0].doCloseMenu();
    },
    openMultipleFile(data){
      this.$store.commit('set_switch_custom_multiple_file', true);
      this.doCloseMenu();
    },
    doTaskSelected(data){
      this.$set(this.data, 'multiple_file', Boolean(!this.data.multiple_file));
      // console.log(this.data);
    },
    async moveOutFromCustom(data){
      this.doCloseMenu();
      const post_data = {
        folderId: this.$global.decodeBase64(this.$route.params.folderid),
        deleteIds: this.data.id,
      }
      this.$openConfirm({
        title:`确定移除任务'${this.data.name}'?`,
        desc:'',
        sure:'确认',
        cancel:'取消',
        onSure:async ()=>{
          let res = await this.$global.doPost({
            url: '/projectCustom/addORdeleteFolderTask',
            data: post_data,
            notice: '移除失败',
          })
          if(res.message == 'success'){
            this.$notice({desc: '移除成功', type: 'success'});
            // 移除
            const {custom_files} = this.$store.state;
            const index = custom_files.find(item=> {
              return item.id == this.data.id;
            })
            if(index != -1){
              custom_files.splice(index, 1);
            }
          }
        },
        onCancel:()=>{},
      })
     
    },
    openTask(){
      this.taskClickHandler();
    },
    taskClickHandler(){
      this.$api.moduleTask.openTask({taskId: this.data.id, config: {edit: true}});
      // this.$api.task.openTask({taskId: this.data.id});
      this.doCloseMenu();
    },
    shareTask(){
      this.doCloseMenu();
      const {commit, state} = this.$store;
      commit('set_switch_multiple_contacts', true);
      commit('set_share_data', this.data);
      commit('set_multiple_contacts_callback', this.$api.share.task);
    },
    callTask(){
      this.$api.task.callTask({taskId: this.data.id});
    },
    callCustomTask(data){
      this.$emit('handleClick', data);
    }
  }
  
}
</script>