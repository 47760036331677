var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "folder_page cp" }, [
    _c(
      "div",
      {
        staticClass: "operation_group",
        on: {
          dblclick: function ($event) {
            return _vm.routerToFolder()
          },
        },
      },
      [
        _c("img", {
          staticClass: "image un_sel",
          attrs: { src: require("@/assets/images/custom/folder.png"), alt: "" },
        }),
        _c(
          "el-popover",
          {
            attrs: {
              "visible-arrow": false,
              offset: -5,
              placement: "right-start",
              width: "400",
              trigger: "click",
              "popper-class": "custom_folder_menu_popover",
            },
            on: {
              show: function ($event) {
                return _vm.popoverShowHandler()
              },
            },
            model: {
              value: _vm.switch_popover,
              callback: function ($$v) {
                _vm.switch_popover = $$v
              },
              expression: "switch_popover",
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.start_moving,
                    expression: "!start_moving",
                  },
                ],
                ref: "menu_group",
                staticClass: "menu_group",
              },
              [
                _c("div", { staticClass: "menu_list_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item un_sel cp",
                      on: {
                        click: function ($event) {
                          return _vm.doRename()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/custom/menu/rename.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [_vm._v("重新命名")]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "menu_list_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item un_sel cp",
                      on: {
                        click: function ($event) {
                          return _vm.startMove()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/custom/menu/move.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [_vm._v("移动至")]),
                      _c("img", {
                        staticClass: "arrow",
                        attrs: {
                          src: require("@/assets/images/custom/menu/arrow.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item un_sel cp",
                      on: {
                        click: function ($event) {
                          return _vm.doDelete()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/custom/menu/delete.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v("删除此分组"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item un_sel cp",
                      on: {
                        click: function ($event) {
                          return _vm.openMultipleFolder()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/custom/menu/multiple.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [_vm._v("多选分组")]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm.start_moving
              ? _c(
                  "div",
                  {
                    staticClass: "moving_path_wrapper",
                    style: {
                      width: `${_vm.moving_path_width}px`,
                      "z-index": _vm.moving_path_z_index,
                    },
                  },
                  [
                    _vm.start_moving
                      ? _c("moving-path", {
                          attrs: {
                            mask: false,
                            multiple: false,
                            type: "folder",
                            id: String(_vm.data.id),
                            max_height: String(_vm.moving_max_height),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.switch_custom_multiple_folder && !_vm.call_custom,
                  expression: "!switch_custom_multiple_folder && !call_custom",
                },
              ],
              staticClass: "menu_btn cp",
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  return _vm.$store.commit("set_switch_moving_path", false)
                },
              },
              slot: "reference",
            }),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.switch_custom_multiple_folder,
                expression: "switch_custom_multiple_folder",
              },
            ],
            staticClass: "select_group cp",
          },
          [
            _c("div", {
              staticClass: "select",
              class: { on: _vm.data.multiple_folder },
              on: {
                click: function ($event) {
                  return _vm.doSelect()
                },
              },
            }),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "name_group" }, [_vm._v(_vm._s(_vm.data.name))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }