var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("task", {
        attrs: {
          data: _vm.data,
          type: "custom",
          open_selected: _vm.switch_custom_multiple_file,
          selected: _vm.data.multiple_file,
          hide_menu: _vm.switch_custom_multiple_folder,
          call_custom: _vm.call_custom,
        },
        on: { handleClick: _vm.callCustomTask },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }