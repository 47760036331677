var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "breadcrumb_page" },
    [
      _vm.data.upFolder
        ? [
            _c("breadcrumb", {
              attrs: { data: _vm.data.upFolder, call_custom: _vm.call_custom },
              on: { handleChangeFolder: _vm.changeFolder },
            }),
          ]
        : _vm._e(),
      _c("div", { staticClass: "text" }, [_vm._v(" > ")]),
      _c(
        "div",
        {
          staticClass: "text cp link",
          class: { current: _vm.current },
          on: {
            click: function ($event) {
              return _vm.goToLink()
            },
          },
        },
        [_vm._v(_vm._s(_vm.data.name))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }