var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view_page" }, [
    _vm.status_title_bar
      ? _c(
          "div",
          {
            staticClass: "title_group",
            class: { call_custom: _vm.call_custom },
          },
          [_vm._v("分组")]
        )
      : _vm._e(),
    _vm.no_folder
      ? _c("div", { staticClass: "no_data" }, [
          _vm._v(
            _vm._s(
              _vm.call_custom ? "暂无自定义分类" : "点击新建分组开始自定义分类"
            )
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        ref: "folders_group",
        staticClass: "folders_group flex",
        class: { is_top: _vm.is_top, call_custom: _vm.call_custom },
        attrs: { id: "folders_group" },
      },
      [
        _vm._l(_vm.custom_folders, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "floder" },
            [
              _c("folder", {
                attrs: { data: item, call_custom: _vm.call_custom },
                on: { handleClick: _vm.selectFolder },
              }),
            ],
            1
          )
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading_folder,
                expression: "loading_folder",
              },
            ],
            staticClass: "loading_folder",
          },
          [_c("loading-icon")],
          1
        ),
      ],
      2
    ),
    _vm.status_title_bar
      ? _c(
          "div",
          {
            staticClass: "title_group",
            class: { call_custom: _vm.call_custom },
          },
          [_vm._v("任务")]
        )
      : _vm._e(),
    _vm.no_file
      ? _c("div", { staticClass: "no_data" }, [
          _vm._v("点击添加任务开始添加任务"),
        ])
      : _vm._e(),
    _vm.status_task_group
      ? _c(
          "div",
          {
            ref: "files_group",
            staticClass: "files_group",
            attrs: { id: "files_group" },
          },
          [
            _c(
              "div",
              { staticClass: "files_list flex" },
              [
                _vm._l(_vm.custom_files, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "file" },
                    [
                      _c("file", {
                        attrs: { call_custom: _vm.call_custom, data: item },
                        on: { handleClick: _vm.callCustomTask },
                      }),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading_file,
                        expression: "loading_file",
                      },
                    ],
                    staticClass: "loading_file",
                  },
                  [_c("loading-icon")],
                  1
                ),
              ],
              2
            ),
            _vm.no_more_files && !_vm.no_file
              ? _c("div", { staticClass: "no_more" }, [
                  _vm._v("没有更多数据了"),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }