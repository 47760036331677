<!--
 * @Author       : Hugo
 * @Date         : 2021-04-28 11:26:41
 * @LastEditTime: 2022-05-19 15:43:23
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/Common/Task.vue
 * @Description  : 公共task组件
 * @^_^          : 
-->
<template>
  <div class="task_page">
    <div class="container flex">
      <div class="select_group"
        v-if="open_selected"
      >
        <div class="icon cp"
          :class="{on: selected}"
          @click="doSelected();"
        ></div>
      </div>
      <div class="task_group flexsb cp"
        @click="taskClickHandler()"
      >
        <div class="name_group flexsb">
          <!--<div class="name">{{data.name}}</div>-->
          <div class="name">
            <title-status-tag
              class="status_tag_group"
              :level-status="String(data.levelStatus)"
              :process-status="String(data.processStatus)"
              size="14px"
            ></title-status-tag>
            <span class="task_name">
              {{ data.name }}
            </span>
          </div>
          <el-popover
            placement="right-start"
            width="400"
            trigger="click"
            :visible-arrow="false"
            popper-class="custom_folder_menu_popover"
            v-model="switch_popover"
            @hide="hidePopoverHandler()"
            @show="popoverShowHandler()"
          > 
            <!-- 自定义文件夹菜单 -->
            <div class="menu_group"
              v-if="status_custom_menu && !start_moving"
              ref="menu_group"
            >
              <div class="menu_list_group">
                <div class="item un_sel cp"
                  @click="startMove();"
                >
                  <img src="~@/assets/images/custom/menu/move.png" alt="" class="icon">
                  <div class="text">移动至</div>
                  <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
                </div>
                <div class="item un_sel cp"
                  @click="moveOutFromCustom();"
                >
                  <img src="~@/assets/images/custom/menu/move_out.png" alt="" class="icon">
                  <div class="text">移出自定义</div>
                </div>
                <div class="item un_sel cp"
                  @click="openMultipleFile();"
                >
                  <img src="~@/assets/images/custom/menu/multiple.png" alt="" class="icon">
                  <div class="text">多选任务</div>
                </div>
                <div class="item un_sel cp"
                  @click="openTask()"
                >
                  <img src="~@/assets/images/custom/menu/open.png" alt="" class="icon">
                  <div class="text">打开任务</div>
                </div>
                <div class="item un_sel cp"
                  @click="callTask()"
                >
                  <img src="~@/assets/images/custom/menu/call.png" alt="" class="icon">
                  <div class="text">调用任务</div>
                </div>
                <div class="item un_sel cp"
                  @click="shareTask()"
                >
                  <img src="~@/assets/images/custom/menu/share.png" alt="" class="icon">
                  <div class="text">分享任务</div>
                </div>
                <!-- <div class="item un_sel cp"
                  @click="setTag()"
                >
                  <img src="~@/assets/images/custom/menu/tag.png" alt="" class="icon">
                  <div class="text">设置标签</div>
                </div> -->
                <!-- <div class="item un_sel cp"
                  v-if="is_created_user"
                  @click="deleteTask();"
                >
                  <img src="~@/assets/images/custom/menu/delete.png" alt="" class="icon">
                  <div class="text">删除任务</div>
                </div> -->
              </div>
            </div>
            <div class="moving_path_wrapper"
              v-if="switch_popover && start_moving"
              :style="{width: `${moving_path_width}px`, 'z-index': moving_path_z_index}"
            >
              <moving-path
                v-if="switch_popover && start_moving"
                :mask="false"
                :multiple="false"
                type="file"
                :id="String(data.id)"
                :max_height="String(moving_max_height)"
              ></moving-path>
            </div>
            <button class="more_btn button un_sel" slot="reference"
              v-show="status_menu_btn && !hide_menu"
              @click="$api.stopPropagation($event);"
            ></button>
          </el-popover>
        </div>
        <div class="time_group">
          <div class="time">{{data.updateTime | universalDate}}</div>
        </div>
      </div>
      <div class="info_group flexsb">
        <div class="avatar_group">
          <el-tooltip
            class="item"
            effect="dark"
            :content="data.createusername"
            placement="top-start"
            :open-delay="500"
          >
            <img 
              :src="`${$avatar_url}${data.createuserthumbnail}@!small200`"
              alt=""
              class="avatar"
              v-if="status_avatar"
            >
          </el-tooltip>
        </div>
        <div class="tag_group flex">
          <div class="tag teach" v-if="is_teach">教</div>
        </div>
      </div>

    </div>
    <div class="from_group flex">
      <div class="text">来源:</div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="data.from"
        placement="bottom-start"
        :open-delay="500"
      >
        <div class="name">{{data.from}}</div>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
import MovingPath from '@/components/Common/MovingPath/Container.vue';
export default {
  components: {
    MovingPath,
    TitleStatusTag
  },
  props: {
    // 元数据
    data: {
      type: Object,
      default : function(){
        return {
          name: 'VIVO的KV延展设计的详细要求描述。VIVO的KV延展设计的详细要求描述。VIVO的KV延展设计的详细要求描述。',
          time: new Date().getTime(),
        }
      }
    },
    open_selected: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    /**
     * preview 可以预览的
     * custom 自定义
     */
    type: {
      type: String,
      default: 'normal',
    },
    hide_menu: {
      type: Boolean,
      default: false,
    },
    call_custom: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      start_moving: false,
      switch_popover: false,
      moving_path_width: 0,
      moving_path_z_index: 0,
      moving_max_height: '451',
    }
  },
  computed:{
    // ******** VUEX
    switch_moving_path(){
      return this.$store.state.switch_moving_path;
    },
    close_task_menu_of_moving(){
      return this.$store.state.close_task_menu_of_moving;
    },
    // ********  IS
    is_teach(){
      const {data} = this;
      const {taskType} = data;
      if(taskType == 2 || taskType == 3){
        return true;
      }
      return false;
    },
    // 是否作者
    is_sign(){
      const userid = this.$tools.getUserId();
      const {data} = this;
      const {signUserId} = data;
      return userid == signUserId;
    },
    // 是否创建者
    is_created_user(){
      const userid = this.$tools.getUserId();
      const {data} = this;
      const {userId} = data;
      if(userId == userid){
        return true;
      }
      return false;
    },
    // ********  STATUS
    // 是否显示头像
    status_avatar(){
      const {data, is_sign} = this;
      const {taskType} = data;
      if(!is_sign && taskType == 2){
        return false;
      }
      return true;
    },
    // 自定义菜单
    status_custom_menu(){
      const {type} = this;
      if(type == 'custom'){
        return true;
      }
      return false

    },
    // 菜单按钮
    status_menu_btn(){
      const {type, open_selected} = this;
      // console.log( open_selected)
      if(type != 'custom'){
        return false;
      }
      if(open_selected){
        return false;
      }
      return true;
    }
  },
  watch: {
    close_task_menu_of_moving: function(val){
      if(val){
        this.doCloseMenu();
        this.$nextTick(function(){
          this.$store.commit('set_close_task_menu_of_moving', false);
        })
        // console.log(this.switch_popover);
      }
    },
    switch_moving_path: function(val){
      if(val == false){
        this.doCloseMenu();
      }
    }
  },  
  methods: {
    /**
     * @description: 关闭菜单
     * @param {*}
     * @return {*}
     */
    doCloseMenu(){
      this.start_moving = false;
      this.switch_popover = false;
    },
    /**
     * @description: 选择
     * @param {*}
     * @return {*}
     */
    doSelected(){
      this.$parent.doTaskSelected(this.data);
    },
    /**
     * @description: 点击钩子
     * @param {*}
     * @return {*}
     */    
    taskClickHandler(){
      const {call_custom} = this;
      if(call_custom){
        this.$emit('handleClick', this.data );
        return;
      }
      this.$parent.taskClickHandler(this.data);
    },
    /**
     * @description: 移出自定义分组
     * @param {*}
     * @return {*}
     */
    moveOutFromCustom(){
      // const membership_privileges = this.$api.user.membershipPrivileges({hint: '该操作为会员权益，当前用户暂无权限'});
      // if(!membership_privileges){
      //   return ;
      // }
      this.$parent.moveOutFromCustom(this.data);
    },
    /**
     * @description: 开启多选任务
     * @param {*}
     * @return {*}
     */
    openMultipleFile(){
      this.$parent.openMultipleFile(this.data);
    },
    /**
     * @description: 打开任务
     * @param {*}
     * @return {*}
     */
    openTask(){
      this.$parent.openTask(this.data);
    },
    /**
     * @description: 调用任务
     * @param {*}
     * @return {*}
     */
    callTask(){
      this.$parent.callTask(this.data);
      this.doCloseMenu();
    },
    /**
     * @description: 分享任务
     * @param {*}
     * @return {*}
     */
    shareTask(){
      this.$parent.shareTask(this.data);
    },
    /**
     * @description: 设置标签
     * @param {*}
     * @return {*}
     */
    setTag(){
      this.$parent.setTag(this.data);
    },
    /**
     * @description: 删除任务
     * @param {*}
     * @return {*}
     */
    deleteTask(){
      this.$parent.deleteTask(this.data);
    },
    startMove(){
      // console.log('start_moving')
      this.start_moving = true;
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
      const rect = this.$refs.menu_group.getBoundingClientRect();
      const left = rect.left;
      const top = rect.top;
      // 计算记录最右边宽度 有20边距
      const width = clientWidth - left - 20;
      // 计算记录最下边宽度 有20边距
      const height = clientHeight - top -20;
      this.moving_max_height = (height > 451) ? '451' : height;
      this.moving_path_width = width,
      this.moving_path_z_index = this.$utils.getZIndex();
      // this.switch_moving_path = true;
      this.$store.commit('set_switch_moving_path', true);
    },
    /**
     * @description: 隐藏钩子
     * @param {*}
     * @return {*}
     */
    hidePopoverHandler(){
      this.start_moving = false;
    },
    popoverShowHandler(){
      this.setPopoverZ();
    },
    setPopoverZ(){
      this.$nextTick(() => {
        document.querySelectorAll('.custom_folder_menu_popover').forEach(item=>{
          item.style.zIndex = this.$utils.getZIndex();
        })
      })
    }


  }
}
</script>
<style lang="scss" scoped>
.task_page{

  .container{
    flex-direction: column;
    width: 160px;
    height: 140px;
    background: #fff;
    border: 1px solid #E8EEFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    position: relative;
    z-index: 2;
  }
  .select_group{
    position: absolute;
    width: 22px;
    height: 22px;
    top:-7px;
    left: -7px;
    .icon{
      @include transition;
      @include background;
      width: 22px;
      height: 22px;
      background-image: url(~@/assets/images/custom/unselected.png);
      &.on{
        background-image: url(~@/assets/images/custom/selected.png);
      }
    }
  }
  .task_group{
    @include bbox;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100px;
    border-bottom: 4px solid #F8F8F8;
    padding: 10px 0;
    .name_group{
      @include bbox;
      width: 100%;
      align-items: flex-start;
      padding: {
        left: 14px;
        right: 4px;
      }
    }
    .more_btn{
      @include background(3px, 15px);
      background-image: url(~@/assets/images/custom/menu_btn.png);
      width: 18px;
      height: 18px;
      flex-shrink: 0;
    }
    .name{
      @include text_justify;
      @include multi_line_intercept(3);
      text-align-last: left;
      width: 100%;
      font-size: 12px;
      color: #333333;
      line-height: 18px;
      padding-right: 10px;
    }
    .time_group{
      padding: 0 14px;
    }
    .time{
      @include bbox;
      flex-shrink: 0;
      font-size: 12px;
      border-left: 4px solid #E5E5E5;
      padding-left: 7px;
    }
  }  
  .info_group{
    @include bbox;
    padding: 0 14px;
    width: 100%;
    height: 40px;
    align-items: center;
    .avatar_group{
      .avatar{
        width: 26px;
        height: 26px;
        border-radius: 4px;
        overflow: hidden;
      }
    }
    .tag_group{
      .tag{
        font-size: 12px;
        line-height: 18px;
        width: 26px;
        text-align: center;
        border-radius: 2px;
        &.teach{
          color: #FF9D4D;
          background-color: rgba(255, 114, 0, 0.08);
        }
      }
    }
  }
  .from_group{
    @include bbox;
    padding: 0 4px;
    overflow: hidden;
    width: 160px;
    font-size: 12px;
    padding-top: 10px;
    .text{
      flex-shrink: 0;
      color:#666;
      padding-right: 5px;
    }
    .name{
      @include single_line_intercept;
      flex: 1;
      width: 100%;
      color: $main_blue;

    }
  }
}

</style>
