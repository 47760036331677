<!--
 * @Author       : Hugo
 * @Date         : 2021-04-25 13:44:24
 * @LastEditTime: 2022-05-19 15:45:06
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/FunTemplate/Custom/View/Folder.vue
 * @Description  : [视图]文件夹
 * @^_^          : 
-->
<template>
  <div class="folder_page cp">
    <!-- 操作 -->
    <div class="operation_group"
      @dblclick="routerToFolder();"
    >
      <img src="~@/assets/images/custom/folder.png" alt="" class="image un_sel">
      <el-popover
        :visible-arrow="false"
        :offset="-5"
        placement="right-start"
        width="400"
        trigger="click"
        popper-class="custom_folder_menu_popover"
        v-model="switch_popover"
        @show="popoverShowHandler()"
      >
        <div class="menu_group"
          ref="menu_group"
          v-show="!start_moving"
        >
          <div class="menu_list_group">
            <div class="item un_sel cp"
              @click="doRename()"
            >
              <img src="~@/assets/images/custom/menu/rename.png" alt="" class="icon">
              <div class="text">重新命名</div>
              <!-- <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow"> -->
            </div>
          </div>
          <div class="menu_list_group">
            <div class="item un_sel cp"
              @click="startMove()"
            >
              <img src="~@/assets/images/custom/menu/move.png" alt="" class="icon">
              <div class="text">移动至</div>
              <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
            </div>
            <div class="item un_sel cp"
              @click="doDelete();"
            >
              <img src="~@/assets/images/custom/menu/delete.png" alt="" class="icon">
              <div class="text">删除此分组</div>
            </div>
            <div class="item un_sel cp"
              @click="openMultipleFolder();"
            >
              <img src="~@/assets/images/custom/menu/multiple.png" alt="" class="icon">
              <div class="text">多选分组</div>
            </div>
          </div>
        </div>
        <div class="moving_path_wrapper"
          v-if="start_moving"
          :style="{width: `${moving_path_width}px`, 'z-index': moving_path_z_index}"
        >
          <moving-path
            v-if="start_moving"
            :mask="false"
            :multiple="false"
            type="folder"
            :id="String(data.id)"
            :max_height="String(moving_max_height)"
          ></moving-path>
        </div>
        <div class="menu_btn cp" slot="reference"
          v-show="!switch_custom_multiple_folder && !call_custom"
          @click="$store.commit('set_switch_moving_path', false)"
        ></div>
      </el-popover>
      <div class="select_group cp"
        v-show="switch_custom_multiple_folder"
      >
        <div class="select"
          @click="doSelect();"
          :class="{on: data.multiple_folder}"
        ></div>
      </div>
      
    </div>
    <div class="name_group">{{data.name}}</div>
  </div>
</template>
<script>
import MovingPath from '@/components/Common/MovingPath/Container.vue';
export default {
  components: {
    MovingPath,
  },
  props: {
    data: {
      type: Object,
      default: new Object(),
    },
    call_custom: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      start_moving: false,
      switch_popover: false,
      moving_path_width: 0,
      moving_path_z_index: 0,
      moving_max_height: '451',
      // switch_moving_path: false,
    }
  },
  computed: {
    switch_custom_multiple_file(){
      return this.$store.state.switch_custom_multiple_file;
    },
    switch_custom_multiple_folder(){
      return this.$store.state.switch_custom_multiple_folder;
    },
    switch_moving_path(){
      return this.$store.state.switch_moving_path;
    },

  },
  watch: {
    switch_custom_multiple_folder: function(val){
      if(val){
        this.$set(this.data, 'multiple_folder', false);
      }
    },
    switch_moving_path: function(val){
      if(val == false){
        this.doCloseMenu();
      }
    }
  },
  methods: {
     /**
     * @description: 关闭菜单
     * @param {*}
     * @return {*}
     */
    doCloseMenu(){
      this.start_moving = false;
      this.switch_popover = false;
    },
    /**
     * 进入文件夹
     * 路由跳转
     */
    routerToFolder(){
      const {switch_custom_multiple_folder, call_custom} = this;
      if(call_custom){
        this.$emit('handleClick', this.data);
        return;
      }
      if(switch_custom_multiple_folder){
        // 多选时禁止跳转
        return;
      }
      this.$api.custom.routerToFolder(this.data.id);
    },
    /**
     * @description: 开启多选文件夹
     * @param {*}
     * @return {*}
     */
    openMultipleFolder(){
      this.$store.commit('set_switch_custom_multiple_folder', true);
      this.switch_popover = false;
    },
    doSelect(){
      this.$set(this.data, 'multiple_folder', Boolean(!(this.data.multiple_folder)))
    },
    doRename(){
      this.switch_popover = false;
      this.$openPrompt({
        title: '重命名',
        placeholder: '请输入分组名',
        hint: '未输入分组名',
        sure: '确认',
        cancel: '取消',
        onSure: async ()=>{
          const {state} = this.$store;
          const {data} = this;
          const {id} = data;
          const {utils_prompt} = state;
          // console.log(data, utils_prompt);
          const post_data = {
            folderId: id,
            name: utils_prompt
          }
          let res = await this.$global.doPost({
            url: '/projectCustom/renameFolder',
            data: post_data,
            notice: '重命名失败',
          })
          if(res.message == 'success'){
            this.$set(data, 'name', utils_prompt);
          }

        },
        onCancel: ()=>{},
      })
    },
    doDelete(){
      // const membership_privileges = this.$api.user.membershipPrivileges({hint: '该操作为会员权益，当前用户暂无权限'});
      // if(!membership_privileges){
      //   return ;
      // }
      this.switch_popover = false;
      const {state} = this.$store;
      const {custom_folders} = state;
      const {data} = this;
      const {id, name} = data;
      // 
      this.$openConfirm({
        title:`是否删除分组'${name}'？`,
        desc:'被删除分组内的任务将从自定义分类中移出',
        sure:'确认',
        cancel:'取消',
        onSure:async ()=>{
          const post_data = {
            folderIds: id,
          }
          let res = await this.$global.doPost({
            url: '/projectCustom/deleteFolder',
            data: post_data,
            notice: '删除失败',
          })
          if(res.message == 'success'){
            this.$notice({desc: '删除成功', type:'success'});
            let index = custom_folders.findIndex(item=> {
              return item.id == id;
            })
            if(index != -1){
              custom_folders.splice(index, 1);
            }
          }

        },
        onCancel:()=>{},
      })
    },
    startMove(){
      this.start_moving = true;
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
      const rect = this.$refs.menu_group.getBoundingClientRect();
      const left = rect.left;
      const top = rect.top;
      // 计算记录最右边宽度 有20边距
      const width = clientWidth - left - 20;
      // 计算记录最下边宽度 有20边距
      const height = clientHeight - top -20;
      this.moving_max_height = (height > 451) ? '451' : height;
      this.moving_path_width = width,
      this.moving_path_z_index = this.$utils.getZIndex();
      // this.switch_moving_path = true;
      this.$store.commit('set_switch_moving_path', true);
    },
    popoverShowHandler(){
      this.setPopoverZ();
    },
    setPopoverZ(){
      this.$nextTick(() => {
        document.querySelectorAll('.custom_folder_menu_popover').forEach(item=>{
          item.style.zIndex = this.$utils.getZIndex();
        })
      })
    }
   
  }
}
</script>
<style lang="scss" scoped>
.folder_page{
  .operation_group{
    @include transition;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .image{
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      display: block;
    }
    &:hover{
      background-color: rgba(0,0,0,0.1);
      .menu_btn{
        transform: scale(1);
      }
    }
    .menu_btn{
      @include transition;
      @include background( 3px, 15px);
      transform: scale(0);
      position: absolute;
      right: 0;
      top:6px;
      z-index: 3;
      height: 15px;
      width: 15px;
      background-image: url(~@/assets/images/custom/menu_btn.png);
    }
    .select_group{
      position: absolute;
      z-index: 3;
      top:3px;
      left: 5px;
      width: 22px;
      height: 22px;
      .select{
        background-image: url(~@/assets/images/custom/unselected.png);
        width: 100%;
        height: 100%;
        &.on{
          background-image: url(~@/assets/images/custom/selected.png);
        }
      }
    }

  }
  .name_group{
    @include multi_line_intercept(2);
    // @include text_justify;
    text-align-last: center;
    text-align: center;
    width: 100%;
    padding: {
      // top: 10px;
    }
    font-size: 12px;
    line-height: 18px;
    height: 36px;
    color: #333;
  }
}


</style>

<style lang="scss">
.custom_folder_menu_popover{
  background-color: #333;
  width: auto!important;
  border:none;
  padding: 0;
  // overflow: hidden;
  .menu_group{
    @include flex;
    flex-direction: column;
    align-items: stretch;
  }
  .menu_list_group{
    @include flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding: 5px 0;
    &:last-child{
      border-bottom: none;
    }
    .item{
      @include flexsb;
      @include transition;
      @include bbox;
      &:hover{
        background-color: #000;
      }
      height: 40px;
      padding: 0 10px;
    }
    .icon{
      flex-shrink: 0;
    }
    .arrow{
      flex-shrink: 0;
    }
    .text{
      @include bbox;
      padding: {
        left: 13px;
      }
      flex: 1;
      color: #fff;
      font-size: 14px;
    }
  }
  .moving_path_wrapper{
    position: absolute;
    top:0;
    left:0;
    min-height: 20px;
    // background-color: red;
    width: 100%;
  }
}
</style>

