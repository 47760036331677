<!--
 * @Author       : Hugo
 * @Date         : 2021-04-29 14:18:38
 * @LastEditTime : 2021-08-10 15:36:05
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/FunTemplate/Custom/Breadcrumb.vue
 * @Description  : 面包屑
 * @^_^          : 
-->
<template>
  <div class="breadcrumb_page">
    <template
      v-if="data.upFolder"
    >
      <breadcrumb
        :data="data.upFolder"
        :call_custom="call_custom"
        @handleChangeFolder="changeFolder"
      ></breadcrumb>
    </template>
    <div class="text">&nbsp;&gt;&nbsp;</div>
    <div
      class="text cp link" @click="goToLink();"
      :class="{current}"
    >{{data.name}}</div>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    data: {
      type: Object,
      default: function(){
        return {
          createTime: 1619671001000,
          fid: 34,
          id: 55,
          name: "21",
          upFolder: null,
          updateTime: 1619671001000,
          userId: 4360,
        }
      }
    },
    // 是否当前 [最顶级]
    current: {
      type: Boolean,
      default: false,
    },
    call_custom: {
      type: Boolean,
      default: false,
    },
    folder_id: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    goToLink(){
      const {data, call_custom,} = this;
      if(call_custom){
        this.$emit('handleChangeFolder', data);
        return;
      }
      const {id} = data;
      if(id){
        this.$router.push({name: 'fun_template_my_custom', params: {folderid: this.$tools.btoa(id)}});
      }
    },
    changeFolder(data){
      this.$emit('handleChangeFolder', data);
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb_page{
  @include flex;
  flex-shrink: 0;
  .link{
    @include transition;
    flex-shrink: 0;
    color:#666;
    &:hover, &.current{
      color: $main_blue;
    }
  }
}
</style>
