<!--
 * @Author       : Hugo
 * @Date         : 2021-04-25 12:05:50
 * @LastEditTime : 2021-10-25 14:49:17
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/FunTemplate/Custom/View.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div class="view_page">
    <div class="title_group"
      v-if="status_title_bar"
      :class="{call_custom: call_custom}"
    >分组</div>
    <div class="no_data"
      v-if="no_folder"
    >{{call_custom?'暂无自定义分类':'点击新建分组开始自定义分类'}}</div>
    <div class="folders_group flex"
      :class="{is_top: is_top, call_custom: call_custom}"
      id="folders_group"
      ref="folders_group"
    >
      <div class="floder"
        v-for="(item, index) in custom_folders"
        :key="index"
      >
        <folder
          :data="item"
          :call_custom="call_custom"
          @handleClick="selectFolder"
        ></folder>
      </div>
      <div class="loading_folder"
        v-show="loading_folder"
      >
        <loading-icon></loading-icon>
      </div>
    </div>
    <div class="title_group"
      v-if="status_title_bar"
      :class="{call_custom: call_custom}"
    >任务</div>
    <div class="no_data"
      v-if="no_file"
    >点击添加任务开始添加任务</div>
  
    <div class="files_group"
      v-if="status_task_group"
      id="files_group"
      ref="files_group"
    >
      <div class="files_list flex">
        <div class="file"
          v-for="(item, index) in custom_files"
          :key="index"
        >
          <file
            :call_custom="call_custom"
            :data="item"
            @handleClick="callCustomTask"
          ></file>
        </div>
        <div class="loading_file"
          v-show="loading_file"
        >
          <loading-icon></loading-icon>
        </div>
      </div>
      <div class="no_more"
        v-if="no_more_files && !no_file"
      >没有更多数据了</div>
    </div>
   
  </div>
</template>
<script>
import LoadingIcon from '@/components/basic/loadingIcon.vue';
import Folder from '@/components/FunTemplate/Custom/View/Folder.vue';
import File from '@/components/FunTemplate/Custom/View/File.vue';
export default {
  components: {
    Folder,
    File,
    LoadingIcon
  },
  props: {
    // 用于调用自定义
    call_custom: {
      type: Boolean,
      default: false,
    },
    folder_id: {
      type: Number,
      default: 0,
    }
  },
  data(){
    return {
      sort: 1,
      loading_file: false,
      no_more_files: false,
      loadmore_files_group_critical_value: false,
      loadmore_folders_group_critical_value: false,
      loading_folder: false,
      no_more_folders: false,
      start: 0,
      keyword: '',
    }
  },
  computed: {
    // **********  Vuex  
    custom_folders(){
      return this.$store.state.custom_folders;
    },
    custom_files(){
      return this.$store.state.custom_files;
    },
    switch_reload_custom_folders(){
      return this.$store.state.switch_reload_custom_folders;
    },
    switch_reload_custom_files(){
      return this.$store.state.switch_reload_custom_files;
    },

    // **********  IS  
    // 是否是顶级目录
    is_top(){
      const {folderid} = this.$route.params;
      return Boolean(!folderid); 
    },
    // **********  Status  
    // 是否有文件夹数据
    no_folder(){
      const {custom_folders} = this;
      if(custom_folders && custom_folders.length){
        return false;
      }
      return true;
    },
    no_file(){
      const {custom_files, is_top} = this;
      if(is_top){
        return false;
      }
      if(custom_files && custom_files.length){
        return false;
      }
      return true;
    },
    // 标题栏
    status_title_bar(){
      const {call_custom, folder_id} = this;
      if(call_custom){
        if(folder_id){
          return true;
        }else{
          return false;
        }
      }
      const {is_top} = this;
      return !is_top;
    },
    // 任务组
    status_task_group(){
      const {call_custom, folder_id} = this;
      if(call_custom){
        if(folder_id){
          return true;
        }else{
          return false;
        }
      }
      const {is_top} = this;
      if(is_top){
        return false;
      }
      return true;
    }

  },
  watch: {
   
    '$route.params.folderid': function(){
      this.init()
    },
    loadmore_files_group_critical_value: function(val){
      const {loading_file} = this;
      if(val && !loading_file){
        // console.log('加载文件')
        this.addToFiles();
      }
    },
    loadmore_folders_group_critical_value:function(val){
      const {loading_folder} = this;
      if(val && !loading_folder){
        // console.log('加载文件')
        this.addToFolders();
      }
    },
    switch_reload_custom_folders: async function(val){
      if(val){
        await this.getFolders();
        this.$store.commit('set_switch_reload_custom_folders', false);
      }
    },
    switch_reload_custom_files: async function(val){
      if(val){
        await this.getFiles();
        this.$store.commit('set_switch_reload_custom_files', false);
      }
    }
  },
  methods: {
    async getFolders(){
      this.$store.commit('set_custom_folders', []);
      this.loading_folder = true;
      this.no_more_folders = false;
      const {sort,keyword} = this;
      let folderid = this.getFolderId();
      let data = {fId: folderid, sort, keyword};
      let res = await this.$api.custom.getFolders(data);
      this.loading_folder = false;
      let custom_folders = [];
      if(res.contents && res.contents.pFolderList){
        custom_folders = res.contents.pFolderList;
      }
      this.$store.commit('set_custom_folders', custom_folders);
      // console.log(this.custom_folders);

      if(res.contents && res.contents.pFolderList && res.contents.pFolderList.length < 50){
        this.no_more_folders = true;
      }
      // await this.addToFolders();

    },
    async addToFolders(){
      const {sort, no_more_folders, custom_folders, keyword} = this;
      if(no_more_folders){
        return;
      }
      this.loading_folder = true;
      const folderid = this.getFolderId();
      let data = {
        fId: folderid, 
        sort,
        startId: custom_folders[custom_folders.length - 1].id,
        keyword,
      };
      const res = await this.$api.custom.addToFolders(data);
      let new_custom_folders = [];
      if(res.contents && res.contents.pFolderList){
        new_custom_folders = custom_folders.concat(res.contents.pFolderList);
        this.$store.commit('set_custom_folders', new_custom_folders);
      }
      if(res.contents && res.contents.pFolderList && res.contents.pFolderList.length < 50){
        this.no_more_folders = true;
      }
      this.loading_folder = false;
    },
    async doSearch({keyword}){
      this.keyword = keyword;
      await this.getFolders();
      await this.getFiles();
    },
    async init(){
      this.keyword = '';
      await this.getFolders();
      await this.getFiles();
      this.addEventListener('files_group');
      this.addEventListener('folders_group');
    },
    getFolderId(){
      const {call_custom, folder_id} = this;
      if(call_custom){
        return folder_id;
      }
      let {folderid} = this.$route.params;
      folderid = this.$global.decodeBase64(folderid);
      if(!folderid){
        return 0;
      }
      return folderid;
    },
    async getFiles(){
      this.start = 0;
      this.loading_file = true;
      this.no_more_files = false;
      const {sort,keyword} = this;
      const folderid = this.getFolderId();
      if(!folderid){
        this.loading_file = false;
        return;
      }
      let data = {fId: folderid, sort, keyword};
      // console.log(data);
      let res = await this.$api.custom.getFiles(data);
      this.loading_file = false;
      if(res.contents && res.contents.pFolderTasksList && res.contents.pFolderTasksList.length < 50){
        this.no_more_files = true;
      }
      await this.addToFiles();
     

    },
    async addToFiles(){
      const {sort, no_more_files, custom_files, keyword} = this;
      
      if(no_more_files){
        return;
      }
      this.loading_file = true;
      const folderid = this.getFolderId();
      if(!folderid){
        this.loading_file = false;
        return;
      }
      this.start += 1;
      let data = {
        fId: folderid, 
        sort,
        start:  this.start,
        keyword
      };
      let res = await this.$api.custom.addToFiles(data);
      if(res.contents && res.contents.pFolderTasksList){
        if(res.contents.pFolderTasksList.length < 50){
          this.no_more_files = true;
        }
      }

      this.loading_file = false;
    },

     /**
    * 滚动监听函数
    */
    scrollHandler(refname){
      // // console.log('scrollEventListenerHandler');
      this.scrollTop = this.$refs[refname].scrollTop;
      let status = false;
      if(this.$refs[refname]){
        const {clientHeight, scrollHeight} = this.$refs[refname];
        const {scrollTop} = this;
        // // console.log(scrollHeight - scrollTop - clientHeight)
        if((scrollHeight - scrollTop - clientHeight) < 200){
          status = true;
        }
      }
      // this.loadmore_files_critical_value = status;
      this[`loadmore_${refname}_critical_value`] = status;
    },
    scrollFilesHandler(){
      this.scrollHandler('files_group');
    },
    scrollFoldersHandler(){
      this.scrollHandler('folders_group');
    },

    addEventListener(refname){
      // // console.log('addEventListener');
      if(this.$refs[refname]){

        this.$nextTick(function(){
          this.$refs[refname].scrollTop = 0;
          if(refname == 'files_group'){
            this.$refs[refname].removeEventListener('scroll', this.scrollFilesHandler)
          }else{
            this.$refs[refname].removeEventListener('scroll', this.scrollFoldersHandler)
          }
          //  200ms后开启监听
          setTimeout(() => {
            if(this.$refs[refname]){
              if(refname == 'files_group'){
                this.$refs[refname].addEventListener('scroll', this.scrollFilesHandler, false);
              }else{
                this.$refs[refname].addEventListener('scroll', this.scrollFoldersHandler, false);
              }
              
            }
          }, 200);
          
        })
      }
    },
    /************* 调用自定义 ************* */
    selectFolder(data){
      // console.log(data);
      this.$emit('handleChangeFolder', data);
    },
    callCustomTask(data){
      this.$emit('handleCallTask', data);
    }
  },
  async mounted(){
    this.init();
    // this.$openPrompt({
    //   title:'新建分组',
    //   placeholder: '输入分组名称',
    //   sure: '确定',
    //   cancel: '取消',
    //   onSure: ()=>{},
    //   onCancel: ()=>{},
    // })
  }

}
</script>
<style lang="scss" scoped>
.view_page{
  @include flex;
  @include bbox;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 10px 0;
  .no_data{
    font-size: 14px;
    color: #999;
    line-height: 30px;
    padding: 20px;
    flex-shrink: 0;
  }
  .title_group{
    @include bbox;
    flex-shrink: 0;
    width: 100%;
    font-size: 16px;
    color: $main_blue;
    line-height: 24px;
    border-left: 4px solid $main_blue;
    padding: {
      left: 20px;
    }
    margin: 10px 0;
    &.call_custom{
      margin-left: 20px;
    }
    
  }
  .folders_group{
    @include scrollbar;
    @include bbox;
    width: 100%;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: stretch;
    overflow-y: auto;
    max-height: 126*2px;
    padding: {
      top: 20px;
      left: 30px;
      right: 10px;
    }
    &.call_custom{
      padding: {
        left: 10px;
        right: 10px;
        top:0;
      }
    }
    &.is_top{
      flex: 1;
      max-height: 100%;
    }
    .floder{
      width: 100px;
      margin: {
        right: 20px;
        bottom: 10px;
      }
    }
    .loading_folder{
      width: 120px;
      height: 116px;
    }
  }
  .files_group{
    @include scrollbar;
    @include bbox;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding: {
      top: 20px;
      left: 30px;
      right: 10px;
    }
    .files_list{
      
      width: 100%;
      align-items: stretch;
      flex-wrap: wrap;
    }
    .file{
      margin: {
        right: 20px;
        bottom: 10px;
      }
    }
    .loading_file{
      width: 162px;
      height: 168px;
    }
  }
  .no_more{
    width: 100%;
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    color: #999;
  }
}

</style>