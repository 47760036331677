var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "task_page" }, [
    _c("div", { staticClass: "container flex" }, [
      _vm.open_selected
        ? _c("div", { staticClass: "select_group" }, [
            _c("div", {
              staticClass: "icon cp",
              class: { on: _vm.selected },
              on: {
                click: function ($event) {
                  return _vm.doSelected()
                },
              },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "task_group flexsb cp",
          on: {
            click: function ($event) {
              return _vm.taskClickHandler()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "name_group flexsb" },
            [
              _c(
                "div",
                { staticClass: "name" },
                [
                  _c("title-status-tag", {
                    staticClass: "status_tag_group",
                    attrs: {
                      "level-status": String(_vm.data.levelStatus),
                      "process-status": String(_vm.data.processStatus),
                      size: "14px",
                    },
                  }),
                  _c("span", { staticClass: "task_name" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.data.name) + "\n          "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right-start",
                    width: "400",
                    trigger: "click",
                    "visible-arrow": false,
                    "popper-class": "custom_folder_menu_popover",
                  },
                  on: {
                    hide: function ($event) {
                      return _vm.hidePopoverHandler()
                    },
                    show: function ($event) {
                      return _vm.popoverShowHandler()
                    },
                  },
                  model: {
                    value: _vm.switch_popover,
                    callback: function ($$v) {
                      _vm.switch_popover = $$v
                    },
                    expression: "switch_popover",
                  },
                },
                [
                  _vm.status_custom_menu && !_vm.start_moving
                    ? _c(
                        "div",
                        { ref: "menu_group", staticClass: "menu_group" },
                        [
                          _c("div", { staticClass: "menu_list_group" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.startMove()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/move.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("移动至"),
                                ]),
                                _c("img", {
                                  staticClass: "arrow",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/arrow.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.moveOutFromCustom()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/move_out.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("移出自定义"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.openMultipleFile()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/multiple.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("多选任务"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.openTask()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/open.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("打开任务"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.callTask()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/call.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("调用任务"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.shareTask()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/share.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("分享任务"),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.switch_popover && _vm.start_moving
                    ? _c(
                        "div",
                        {
                          staticClass: "moving_path_wrapper",
                          style: {
                            width: `${_vm.moving_path_width}px`,
                            "z-index": _vm.moving_path_z_index,
                          },
                        },
                        [
                          _vm.switch_popover && _vm.start_moving
                            ? _c("moving-path", {
                                attrs: {
                                  mask: false,
                                  multiple: false,
                                  type: "file",
                                  id: String(_vm.data.id),
                                  max_height: String(_vm.moving_max_height),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.status_menu_btn && !_vm.hide_menu,
                        expression: "status_menu_btn && !hide_menu",
                      },
                    ],
                    staticClass: "more_btn button un_sel",
                    attrs: { slot: "reference" },
                    on: {
                      click: function ($event) {
                        return _vm.$api.stopPropagation($event)
                      },
                    },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "time_group" }, [
            _c("div", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm._f("universalDate")(_vm.data.updateTime))),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "info_group flexsb" }, [
        _c(
          "div",
          { staticClass: "avatar_group" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.data.createusername,
                  placement: "top-start",
                  "open-delay": 500,
                },
              },
              [
                _vm.status_avatar
                  ? _c("img", {
                      staticClass: "avatar",
                      attrs: {
                        src: `${_vm.$avatar_url}${_vm.data.createuserthumbnail}@!small200`,
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "tag_group flex" }, [
          _vm.is_teach
            ? _c("div", { staticClass: "tag teach" }, [_vm._v("教")])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "from_group flex" },
      [
        _c("div", { staticClass: "text" }, [_vm._v("来源:")]),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: _vm.data.from,
              placement: "bottom-start",
              "open-delay": 500,
            },
          },
          [_c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.data.from))])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }